@use '../abstracts/variables';
@use '../abstracts/mixins';

*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	font-size: 62.5%;
}

body {
	box-sizing: border-box;
	background-color: variables.$color-secondary;
}

section {
	margin-bottom: 2em;
}
form {
	@include mixins.mainBorder;
	font-size: 2rem;
	fieldset {
		padding: 2rem;

		&:not(:last-child) {
			margin-bottom: 2rem;
		}
		legend {
			color: variables.$color-primary;
			font-weight: 700;
		}
		label {
			margin-bottom: 1rem;
			font-weight: 700;
			font-size: 1.6rem;
		}
		h3 {
			margin-top: 2rem;
			font-size: 2rem;
		}
	}
}
