@use '../abstracts/variables';
@use '../abstracts/mixins';

.answer {
	@include mixins.mainBorder(variables.$color-primary);
	background-color: white;
	color: variables.$color-primary;
	box-shadow: 2px 2px 4px 4px rgba(variables.$color-primary, 0.35);
	& * {
		color: variables.$color-primary;
	}
	&__header {
		background-color: variables.$color-secondary--light;
		padding: 2em;
		h2 {
			text-transform: uppercase;
			text-align: center;
			font-weight: 700;
		}
	}
	&__container {
		@include mixins.displayFlex(row, space-around, center);
		padding: 1rem;
		@include mixins.responsive(variables.$bp-mobile) {
			padding: 1rem;
		}
		& > * {
			margin: 1em;
		}
		.percents {
			@include mixins.displayFlex(row);
			& > * {
				margin: 0 0.5em;
			}
			h2 {
				font-size: 2.4rem;
			}
			@include mixins.responsive(variables.$bp-mobile) {
				@include mixins.displayFlex(column);
				& > * {
					margin: 0.5em;
					@include mixins.displayFlex(column);
				}
			}
		}
		.totals {
			@extend .percents;
		}
	}
}

.answer {
	@include mixins.responsive(48rem) {
		border-radius: unset;
		border-left: unset;
		border-right: unset;
		margin-bottom: 2rem;
		&__header {
			padding: 1rem;
		}
		&__container {
			@include mixins.displayFlex(column, flex-start, stretch);
			h2 {
				font-size: 2rem;
			}
			.totals,
			.percents {
				@include mixins.displayFlex(row, center);
				margin: 1rem;
			}
		}
		#reset {
			margin-top: 1rem;
		}
	}
}
