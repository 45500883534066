@use '../abstracts/variables';
@use '../abstracts/mixins';

footer {
	@include mixins.displayFlex(column, center, stretch);
	@include mixins.linearGradientDarken;
	background-color: variables.$color-primary;
	color: white;
	padding: 6.5rem 0;
	& > * {
		color: white;
		text-align: center;
	}
	a {
		color: white;
		font-weight: 700;
		&:hover,
		&:focus,
		&:active {
			font-size: 1.8rem;
			@include mixins.transitionStandard;
		}
	}
}
