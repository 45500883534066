@import "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400;1,700&display=swap";
.hidden {
  display: none;
}

.inactive {
  opacity: .6;
  transition: opacity .2s .25s;
}

#sticky {
  position: sticky;
}

*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  background-color: #d1bcb5;
}

section {
  margin-bottom: 2em;
}

form {
  border: .5rem solid #3c1c12;
  border-radius: 1rem;
  font-size: 2rem;
  overflow: hidden;
}

form fieldset {
  padding: 2rem;
}

form fieldset:not(:last-child) {
  margin-bottom: 2rem;
}

form fieldset legend {
  color: #3c1c12;
  font-weight: 700;
}

form fieldset label {
  margin-bottom: 1rem;
  font-size: 1.6rem;
  font-weight: 700;
}

form fieldset h3 {
  margin-top: 2rem;
  font-size: 2rem;
}

body {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
}

h1 {
  font-size: 4.8rem;
  font-weight: 700;
}

h2 {
  font-size: 3.2rem;
  font-weight: 700;
}

h3 {
  font-size: 2.4rem;
  font-weight: 700;
}

input[type="submit"], button[type="submit"], input[type="reset"] {
  letter-spacing: .02rem;
  text-transform: uppercase;
  font-family: Montserrat, Helvetica, sans-serif;
  font-weight: 700;
}

.container {
  width: 88%;
  max-width: 1200px;
  flex-flow: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  margin: 0 auto;
  padding: 6.5rem 0;
  display: flex;
  position: relative;
}

.container > section {
  flex: 40%;
  margin: 0 2rem;
}

@media screen and (max-width: 48rem) {
  .container {
    width: 100%;
    padding-top: 0;
  }

  .container > section:first-child {
    margin-top: 2rem;
  }
}

.container .answer {
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
  position: sticky;
  top: 10%;
}

@media screen and (max-width: 48rem) {
  .container .answer {
    width: 100%;
    flex: auto;
    order: 1;
    margin: 0;
    top: 0%;
  }
}

.container .calculators {
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

@media screen and (max-width: 48rem) {
  .container .calculators {
    order: 2;
  }
}

.container .calculators form {
  margin-bottom: 5vw;
}

.header {
  background-color: #fff;
  border-bottom: 2rem solid #3c1c12;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  padding: 6.5rem 2rem;
  display: flex;
}

.header > * {
  margin-bottom: 3rem;
}

@media screen and (max-width: 48rem) {
  .header {
    padding: 3rem 2rem;
  }
}

.header__title {
  color: #3c1c12;
  margin-left: 5rem;
}

@media screen and (max-width: 48rem) {
  .header__title h1 {
    font-size: 3.2rem;
  }
}

.header__img svg {
  height: 20rem;
  width: 20rem;
}

@media screen and (max-width: 48rem) {
  .header__img svg {
    height: 10rem;
    width: 10rem;
  }
}

footer {
  color: #fff;
  background-color: #3c1c12;
  background-image: linear-gradient(to bottom right, #0000, #00000059);
  flex-flow: column wrap;
  justify-content: center;
  align-items: stretch;
  padding: 6.5rem 0;
  display: flex;
}

footer > * {
  color: #fff;
  text-align: center;
}

footer a {
  color: #fff;
  font-weight: 700;
}

footer a:hover, footer a:focus, footer a:active {
  font-size: 1.8rem;
  transition: all .2s;
}

.form {
  background-color: #fff;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
}

.form * {
  flex: auto;
}

.form__header {
  background-color: #3c1c12;
  background-image: linear-gradient(to bottom right, #0000, #00000059);
  align-self: stretch;
  padding: 2rem;
}

.form__header h2 {
  color: #fff;
  text-align: center;
}

.form__content {
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: stretch;
  padding: 3rem;
  display: flex;
}

.form__content--fields {
  flex-flow: wrap;
  justify-content: space-around;
  align-items: flex-start;
  display: flex;
}

.form__submission {
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2rem;
  display: flex;
}

.form__submission button {
  margin-bottom: 1em;
}

#bmr-calculator {
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
}

#bmr-calculator .form__content {
  flex-flow: wrap;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

#bmr-calculator .form__content :first-child {
  margin-right: 2rem;
}

#bmr-calculator #height {
  margin-top: 2rem;
}

#modifiers .form__content > * {
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

#modifiers .form__content > :not(:last-child) {
  margin-bottom: 1em;
}

#protein-calculator label {
  margin-bottom: 2rem;
}

button, #reset {
  color: #fff;
  cursor: pointer;
  background-color: #875242;
  border: .5rem solid #875242;
  border-radius: 1rem;
  margin-top: 3rem;
  padding: 1rem 2rem;
  font-size: 1.6rem;
  transition: all .2s;
  overflow: hidden;
}

button:hover, #reset:hover, button:focus, #reset:focus, button:active, #reset:active {
  color: #875242;
  background-color: #0000;
  transform: scale(1.03);
  box-shadow: 2px 2px 4px #00000059;
}

#reset {
  margin: 0;
}

.answer {
  color: #3c1c12;
  background-color: #fff;
  border: .5rem solid #3c1c12;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 2px 2px 4px 4px #3c1c1259;
}

.answer * {
  color: #3c1c12;
}

.answer__header {
  background-color: #d2bdb6;
  padding: 2em;
}

.answer__header h2 {
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
}

.answer__container {
  flex-flow: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  display: flex;
}

@media screen and (max-width: 25.875em) {
  .answer__container {
    padding: 1rem;
  }
}

.answer__container > * {
  margin: 1em;
}

.answer__container .percents, .answer__container .totals {
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.answer__container .percents > *, .answer__container .totals > * {
  margin: 0 .5em;
}

.answer__container .percents h2, .answer__container .totals h2 {
  font-size: 2.4rem;
}

@media screen and (max-width: 25.875em) {
  .answer__container .percents, .answer__container .totals {
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
  }

  .answer__container .percents > *, .answer__container .totals > * {
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin: .5em;
    display: flex;
  }
}

@media screen and (max-width: 48rem) {
  .answer {
    border-radius: unset;
    border-left: unset;
    border-right: unset;
    margin-bottom: 2rem;
  }

  .answer__header {
    padding: 1rem;
  }

  .answer__container {
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: stretch;
    display: flex;
  }

  .answer__container h2 {
    font-size: 2rem;
  }

  .answer__container .totals, .answer__container .percents {
    flex-flow: wrap;
    justify-content: center;
    align-items: flex-start;
    margin: 1rem;
    display: flex;
  }

  .answer #reset {
    margin-top: 1rem;
  }
}

/*# sourceMappingURL=index.14e619fc.css.map */
