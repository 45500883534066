@use '../abstracts/variables';
@use '../abstracts/mixins';

body {
	font-family: variables.$font-primary;
	font-weight: 400;
	font-size: 1.6rem;
}

h1 {
	font-size: 4.8rem;
	font-weight: 700;
}
h2 {
	font-weight: 700;
	font-size: 3.2rem;
}
h3 {
	font-weight: 700;
	font-size: 2.4rem;
}
input[type='submit'],
button[type='submit'],
input[type='reset'] {
	font-weight: 700;
	font-family: variables.$font-primary;
	letter-spacing: 0.02rem;
	text-transform: uppercase;
}
