.hidden {
	display: none;
}

.inactive {
	opacity: 0.6;
	transition: opacity 0.2s ease;
	transition-delay: 250ms;
}

#sticky {
	position: sticky;
}
